import { IconEnum } from '~/types/client'
import type { MenuGroup } from '~/types/common'
import { UserRoleEnum } from '~/types/common'

export const menuItems: MenuGroup[] = [
  {
    title: 'Загальне',
    items: [
      {
        name: 'admin',
        title: 'Панель управління',
        icon: IconEnum.dataAnalysis,
        roles: [UserRoleEnum.CONTENT_MANAGER, UserRoleEnum.MODERATOR],
      },
    ],
  },
  {
    title: 'Управління функціоналом',
    items: [
      {
        name: 'admin-header-links',
        title: 'Головні посилання',
        icon: IconEnum.headerLinks,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        name: 'admin-import-ria',
        title: 'Імпорт РІА',
        icon: IconEnum.car,
        roles: [UserRoleEnum.ADMIN],
      },
      // {
      //   name: 'admin-import-ria',
      //   title: 'Импорт Риа',
      //   icon: IconEnum.car,
      //   roles: [UserRoleEnum.ADMIN],
      // },
      // {
      //   name: 'admin-import-ria',
      //   title: 'Импорт Риа',
      //   icon: IconEnum.car,
      //   roles: [UserRoleEnum.ADMIN],
      // },
      // {
      //   name: 'admin-statistic-import',
      //   title: 'Імпорт статистики',
      //   icon: IconEnum.research,
      //   roles: [UserRolesEnum.ADMIN],
      // },
      {
        name: 'admin-statistic-export',
        title: 'Експорт статистики',
        icon: IconEnum.research,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        name: 'admin-brands',
        title: 'Марки',
        icon: IconEnum.car,
        roles: [UserRoleEnum.MODERATOR],
      },
      {
        name: 'admin-moderating',
        title: 'Модерація',
        icon: IconEnum.car,
        roles: [UserRoleEnum.MODERATOR],
      },
      {
        name: 'admin-sections',
        title: 'Характеристики',
        icon: IconEnum.engineering,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        name: 'admin-car-categories',
        title: 'Типи траспорту',
        icon: IconEnum.car,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        name: 'admin-car-classes',
        title: 'Класи автомобілів',
        icon: IconEnum.car,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        name: 'admin-dealers',
        title: 'Дилери',
        icon: IconEnum.dataAnalysis,
        roles: [UserRoleEnum.MODERATOR],
      },
      {
        name: 'admin-users',
        title: 'Користувачі',
        icon: IconEnum.dataAnalysis,
        roles: [UserRoleEnum.CONTENT_MANAGER, UserRoleEnum.MODERATOR],
      },
      {
        name: 'admin-archived-cars',
        title: 'Архівовані авто',
        icon: IconEnum.dataAnalysis,
        roles: [UserRoleEnum.CONTENT_MANAGER, UserRoleEnum.MODERATOR],
      },
      {
        name: 'admin-feedbacks',
        title: 'Зворотній зв\'язок',
        icon: IconEnum.feedbacks,
        roles: [UserRoleEnum.MODERATOR],
      },
      {
        name: 'admin-financial-services',
        title: 'Фінансові сервіси',
        icon: IconEnum.financialServices,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        name: 'admin-sales-leaders',
        title: 'Лідери продажу',
        icon: IconEnum.salesLeaders,
        roles: [UserRoleEnum.MODERATOR],
      },
      {
        name: 'admin-contacts',
        title: 'Контакти',
        icon: IconEnum.feedbacks,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        name: 'admin-complaints',
        title: 'Скарги',
        icon: IconEnum.feedbacks,
        roles: [UserRoleEnum.MODERATOR],
      },
      {
        name: 'admin-metadata',
        title: 'Метаінформація сторінок',
        icon: IconEnum.feedbacks,
        roles: [UserRoleEnum.MODERATOR],
      },
      {
        name: 'admin-tags',
        title: 'Теги',
        icon: IconEnum.feedbacks,
        roles: [UserRoleEnum.MODERATOR],
      },
      {
        name: 'admin-news',
        title: 'Новини',
        icon: IconEnum.feedbacks,
        roles: [UserRoleEnum.MODERATOR],
      },
      {
        name: 'admin-news-authors',
        title: 'Автори новин',
        icon: IconEnum.feedbacks,
        roles: [UserRoleEnum.MODERATOR],
      },
      {
        name: 'admin-setting',
        title: 'Налаштування',
        icon: IconEnum.engineering,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        name: 'admin-sms-logs',
        title: 'SMS логи',
        icon: IconEnum.smsLogs,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        name: 'admin-promotion',
        title: 'Просування',
        icon: IconEnum.engineering,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        name: 'admin-external-banners',
        title: 'Банери',
        icon: IconEnum.engineering,
        roles: [UserRoleEnum.ADMIN],
      },
    ],
  },
]
